<template>
    <admin-template>
        <template slot="drawers">
            <vehicle-create-drawer v-model="showCreateDrawer" />

            <vehicle-edit-drawer v-model="showEditDrawer" :vehicle="selectedVehicle" />
        </template>

        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <span class="headline">Vehiculos</span>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-toolbar-title>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                fab
                                x-small
                                color="primary"
                                class="elevation-0"
                                @click.stop="onNewSelected"
                                v-on="on"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Nueva Categoría</span>
                    </v-tooltip>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <v-data-table
                                hide-default-footer
                                hide-default-header
                                :items="vehicles"
                                :loading="isLoading"
                                :headers="headers"
                                :items-per-page="-1"
                            >
                                <template v-slot:item.name="{ item }">{{ item.name | capitalize }}</template>

                                <template v-slot:item.driver="{ item }">{{ item.driver.name }}</template>
"
                                <template v-slot:item.actions="{ item }">
                                    <v-btn icon @click.stop="onEditSelected(item)">
                                        <v-icon>mdi-pencil-outline</v-icon>
                                    </v-btn>

                                    <v-btn icon @click.stop="onScheduleSelected(item)">
                                        <v-icon>mdi-clock-outline</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import VehicleCreateDrawer from '@/pages/Admin/Vehicles/VehicleCreateDrawer';
import VehicleEditDrawer from '@/pages/Admin/Vehicles/VehicleEditDrawer';
import AdminTemplate from '../AdminTemplate.vue';

export default {
    components: {
        VehicleCreateDrawer,
        VehicleEditDrawer,
        AdminTemplate
    },

    data() {
        return {
            showCreateDrawer: false,
            showEditDrawer: false,
            showScheduleDrawer: false,
            showResetPasswordDrawer: false,

            selectedVehicle: null,

            headers: [
                { text: 'Nombre', value: 'name' },
                { text: 'Marca', value: 'makeAndModel' },
                { text: 'Patente', value: 'license' },
                { text: 'Conductor', value: 'driver' },
                { text: 'Acciones', value: 'actions', align: 'end' }
            ]
        };
    },

    computed: {
        isLoading() {
            return this.$store.getters['vehicles/isLoading']();
        },

        vehicles() {
            return this.$store.getters['vehicles/getAll']();
        }
    },

    watch: {
        showEditDrawer(val) {
            if (val) {
                this.showCreateDrawer = false;
            }
        },

        showCreateDrawer(val) {
            if (val) {
                this.showEditDrawer = false;
            }
        }
    },

    mounted() {
        this.$store.dispatch('vehicles/fetchAll', {});
    },

    methods: {
        onNewSelected() {
            this.showCreateDrawer = true;
            this.selectedVehicle = null;
        },

        onEditSelected(vehicle) {
            this.showEditDrawer = true;
            this.selectedVehicle = vehicle;
        },

        onScheduleSelected(vehicle) {
            this.$router.push({ name: 'adminVehicleSchedule', params: { id: vehicle.id } }).catch(err => { });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
