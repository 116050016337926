var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"right":"","fixed":"","temporary":"","width":"600"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-row',{staticClass:"ma-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","dark":"","block":"","loading":_vm.isLoading},on:{"click":_vm.onSaveSelected}},[_vm._v("Guardar")])],1)]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.vehicle)?_c('v-container',{staticClass:"pa-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"center",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"black--text",attrs:{"icon":""},on:{"click":_vm.onBackSelected}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('span',{staticClass:"headline"},[_vm._v("Editar Vehiculo")])],1)],1)],1),_c('validation-observer',{ref:"form"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,false,3573428969)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"makeAndModel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Marca y Modelo","error-messages":errors},model:{value:(_vm.data.makeAndModel),callback:function ($$v) {_vm.$set(_vm.data, "makeAndModel", $$v)},expression:"data.makeAndModel"}})]}}],null,false,3076277844)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"license","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Patente","error-messages":errors},model:{value:(_vm.data.license),callback:function ($$v) {_vm.$set(_vm.data, "license", $$v)},expression:"data.license"}})]}}],null,false,4155796307)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Capacidad","error-messages":errors},model:{value:(_vm.data.capacity),callback:function ($$v) {_vm.$set(_vm.data, "capacity", $$v)},expression:"data.capacity"}})]}}],null,false,3920805371)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Características","items":_vm.features,"item-text":"name","item-value":"id","return-object":"","multiple":""},model:{value:(_vm.data.features),callback:function ($$v) {_vm.$set(_vm.data, "features", $$v)},expression:"data.features"}})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }