var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('admin-template',[_c('template',{slot:"drawers"},[_c('vehicle-create-drawer',{model:{value:(_vm.showCreateDrawer),callback:function ($$v) {_vm.showCreateDrawer=$$v},expression:"showCreateDrawer"}}),_c('vehicle-edit-drawer',{attrs:{"vehicle":_vm.selectedVehicle},model:{value:(_vm.showEditDrawer),callback:function ($$v) {_vm.showEditDrawer=$$v},expression:"showEditDrawer"}})],1),_c('template',{slot:"content"},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Vehiculos")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-0",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.onNewSelected.apply(null, arguments)}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nueva Categoría")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.vehicles,"loading":_vm.isLoading,"headers":_vm.headers,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("capitalize")(item.name)))]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.driver.name))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEditSelected(item)}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onScheduleSelected(item)}}},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1)]}}])},[_vm._v(" \" ")])],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }